import { useEffect, useState } from 'react';

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import './App.css';
import Button from '@mui/material/Button';

import FormControl from '@mui/material/FormControl';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';


import { createTheme } from '@mui/material/styles';
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CachedIcon from '@mui/icons-material/Cached';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Paper from '@mui/material/Paper';
import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded';


function App() {

  const [imagePath, setImagePath] = useState("images/gradient.png")
  const [orderAmount, setOrderAmount] = useState(1)
  const [buyButtonText, setBuyButtonText] = useState("Buy " + orderAmount + " for €" + (orderAmount*80))
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [variant, setVariant] = useState('contained');
  const [buttonIcon, setButtonIcon] = useState(<ShoppingCartCheckoutOutlinedIcon/>);
  const [containerHeight, setContainerHeight] = useState(window.innerHeight);
  const [colorDark, setColorDark] = useState('#ff574c');
  const [colorLight, setColorLight] = useState('#ffa09b');
  const [selectedColor, setSelectedColor] = useState('red');
  const [triggerReload, setTriggerReload] = useState(false);
  const [colorIndex, setColorIndex] = useState(1);
  const [chipData, setChipData] = useState([
    { key: 0, label: 'lamp' },
    { key: 1, label: 'wide' },
    { key: 2, label: 'curved' },
    { key: 3, label: 'short' },
    { key: 4, label: 'flat bottom' },
    { key: 5, label: 'pink background' }
  ])
  const images = [
    {
      original: imagePath,
      thumbnail: imagePath,
      originalAlt: "LuminAIr",
      thumbnailAlt: "LuminAIr"
    },
    {
      original: "images/3dtexture.jpg",
      thumbnail: "images/3dtexture.jpg",
      originalAlt: "Closeup that shows the 3D printed texture",
      thumbnailAlt: "Closeup that shows the 3D printed texture"
    },
    {
      original: "images/ceilingmount.jpg",
      thumbnail: "images/ceilingmount.jpg",
      originalAlt: "Closeup of the ceiling mount",
      thumbnailAlt: "Closeup of the ceiling mount"
    }
  ]
  const theme =  createTheme( {
    palette: {
      action: {
        disabledBackground: colorLight,
        disabled: colorLight
      },
      mode: 'light',
      primary: {
        main: colorLight,
        dark: colorDark,
        contrastText: '#ffffff',
      },
      text: {
        primary: colorLight
      },
    },
    typography: {
      button: {
        fontFamily: 'inter',
        textTransform: 'normal',
        letterSpacing: '0.12em',
        fontWeight: '500'
      },
      fontFamily: 'inter',
    }
  });


  const colors = [
    {name: 'red', dark: '#ff574c', light: '#ffa09b', hue: '0deg'},
    {name: 'orange', dark: '#FF9500', light: '#FFD08F', hue: '30deg'},
    {name: 'green', dark: '#00B573', light: '#83EAC4', hue: '170deg'},
    {name: 'blue', dark: '#00A2FF', light: '#8FD6FF', hue: '210deg'},
   
  ]

  const updateContainerHeight = () => {
    setContainerHeight(Math.max(window.innerHeight, 512));
  };

  // Attach an event listener to window resize
  useEffect(() => {
    window.addEventListener('resize', updateContainerHeight);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateContainerHeight);
    };
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--colorDark', colorDark);
    document.documentElement.style.setProperty('--colorLight', colorLight);
  }, [colorDark,colorLight]);


  useEffect(() => {
    colors.forEach((data, index) => {
      if (data.name === selectedColor){
        setColorDark(data.dark)
        setColorLight(data.light)
        document.documentElement.style.setProperty('--hue', data.hue);
        setColorIndex(index+1)
      }
    })
  }, [selectedColor]);


  useEffect(() => {
    setBuyButtonText(
    <div>
      <span>Buy {orderAmount} </span>
      <bold>|</bold>
      <bold >€{orderAmount*80}</bold>
    </div>)
  }, [orderAmount])

  useEffect(() => {

      fetch('images/amount.txt')
      .then(r => r.text())
      .then(text => {
        const randomIndex =Math.floor(Math.random() * (parseFloat(text) - 1.0) + 1.0);
        setImagePath("images/redlamp (" + randomIndex + ").png");
          console.log("Image #" + randomIndex)
        // eerst blur op de niew geslecteerde afbeelding (add class 'blur' )
          // Find all <Image> elements in the DOM
          const imageElements = document.querySelectorAll('img');
          const promptElements = document.querySelectorAll('.prompt');
          // Add a class to each <Image> element
          imageElements.forEach((image) => {
            image.classList.add('blur');
          });
          promptElements.forEach((prompt) => {
            prompt.classList.add('blur');
          });

          const promptPath = "images/redlamp (" + randomIndex + ").txt";
          fetch(promptPath)
          .then(r => r.text())
          .then(text => {
            const sliceIndex = text.indexOf("white background");
            console.log(text)
            const prunePromptOne =  text.slice(41,sliceIndex-1)
            console.log(prunePromptOne)
            const prunePromptTwo = prunePromptOne.replaceAll(/\s/g, '+').replaceAll(',+,+,+,+,+', ',+').replaceAll(',+,+,+,+', ',+').replaceAll(',+,+,+', ',+').replaceAll(',+,+', ',+').replaceAll('+', ' ')
            console.log(prunePromptTwo)
            const prunePromptThree = "lamp, " + prunePromptTwo.trimStart() + ", pink background";
            console.log(prunePromptThree)
            const prunePromptFour = prunePromptThree.replaceAll(/\s/g, '+').replaceAll(',+,+,+,+,+', ',+').replaceAll(',+,+,+,+', ',+').replaceAll(',+,+,+', ',+').replaceAll(',+,+', ',+').replaceAll('+', ' ')
            console.log(prunePromptFour)
            const promptArray = prunePromptFour.split(', ');
            const promptLabelArray =  promptArray.map((word, i) => ({key: [i], label : [word] }));
            setChipData(promptLabelArray)
          })

      }).catch(error => console.log(error));
  }, [triggerReload])


  return (
    <div className="App" style={{height: containerHeight, display: 'flex', alignItems: 'center' }}>
      <header className="App-header">
      </header>
      <div className='container'>
      <h1 className='mobileTitle'>LuminAIr <br></br>
            <small> One of a kind design that doesn't exist, every page reload </small>
          </h1>
          
      <ImageGallery items={images} 
        showPlayButton={false}
        showFullscreenButton={false}
        showNav={false}
      />
        <div className='productInfo'>
          <h1 className='webTitle'>LuminAIr <br></br>
            <small> One of a kind design that doesn't exist, every page reload </small>
          </h1>
          <div className='buyContainer'>
                  <Paper sx={{padding: '20px', paddingTop:'15px'}}>
                  <FormControl sx={{display:'flex', flexDirection:'column', gap:'10px'}} size={"small" }>
                        <div className='extraButtons'>
                                            <Button theme={theme} variant='text' disableElevation startIcon={<CachedIcon fontSize="small" />}
                                onClick={() => {
                                  const imageElements = document.querySelectorAll('img');
                                  const promptElements = document.querySelectorAll('.prompt');
                                  imageElements.forEach((image) => {
                                    image.classList.remove('blur');
                                  });
                                  promptElements.forEach((prompt) => {
                                    prompt.classList.remove('blur');
                                  });
                                  setImagePath("images/gradient.png");
                                  if(triggerReload){
                                    setTriggerReload(false)
                                  } else {setTriggerReload(true)}
                            }}  
                          >new design
                        </Button>
                        <Button theme={theme} variant='text' disableElevation startIcon={<ColorLensRoundedIcon />}
                                onClick={() => {
                                  colors.forEach((data, index) => {
                                    if(selectedColor === data.name){
                                      setSelectedColor(colors[(index+1)%colors.length].name)
                                    }
                                  })
                                }}
                          >colour {colorIndex}/{colors.length}</Button>
                        </div>

                      <ButtonGroup> 
                      <Button theme={theme} variant={variant} disableElevation startIcon={buttonIcon} sx={{}}
                          onClick={() => {
                            window.gtag('event', 'add_to_cart', {
                              'item_id': imagePath,
                              'currency': 'Euro',
                              'value' : orderAmount
                            });
                            setBuyButtonText("not today :(")
                            setButtonDisabled(true);
                            setVariant('outlined')
                            setButtonIcon()


                      }} disabled={isButtonDisabled} id='buyButton'
                    >{buyButtonText}</Button>
                        <Button theme={theme} disabled={isButtonDisabled}
                          aria-label="reduce"
                          onClick={() => {
                            setOrderAmount(Math.max(orderAmount - 1, 1));
                          }}
                        >
                          <RemoveIcon fontSize="small" />
                        </Button>
                        <Button theme={theme} disabled={isButtonDisabled}
                          aria-label="increase"
                          onClick={() => {
                            setOrderAmount(Math.min(orderAmount + 1, 9));
                          }}
                        >
                          <AddIcon fontSize="small" />
                        </Button>
                    </ButtonGroup>
                  </FormControl>
                  </Paper>
          </div>
          <Divider color='#ffa09b' theme={theme} variant="midle" sx={{marginTop: '30px'}}/>
          <div className='highlightContainer'>
            <div className='highlightTextContainer'><span className='bigHighlight'>E27</span><span className='smallHighlight'>Bulb socket</span></div>
            <div className='highlightTextContainer'><span className='bigHighlight'>PETG</span><span className='smallHighlight'>Recycled plastic</span></div>
            <div className='highlightTextContainer'><span className='bigHighlight'>LWH?</span><span className='smallHighlight'>Varying dimensions</span></div>
          </div>
          <div className='prompt'> 
            {chipData.map((data) => {
              return (
                  <Chip theme={theme} variant="outlined" color='primary' size='small'
                    label={data.label} key={data.key}
                  />
              )
            })}
          </div>
          <p>            
Discover the futuristic LuminAIr Lamp - an awe-inspiring 3D printed masterpiece crafted by AI and diffusion models. Each luminaire is a unique creation that defies reality, casting an ethereal glow that captivates the senses. Let this cutting-edge design be the focal point of your space, igniting conversations and elevating your ambiance to a new realm of innovation and wonder. Illuminate your world with brilliance that knows no bounds!
          </p>
        </div>
      </div>
    </div>
  );
}
export default App;
